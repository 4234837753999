import React from "react";
import {Col, Row} from "react-bootstrap";
import './MagicBoxCard.scss';

const MagicBoxCard = (props) => {

	const {imageUrl, text, title} = props;


	return(
		<>
			<Row className='desktop-magic-box-card card-shadow d-none d-md-flex'>

				<Col className='card-illustration ' xs={3}>
					<img className="client-custom-border-color-secondary" src={imageUrl} alt=''/>
				</Col>

				<Col className='card-text' xs={9}>
					<h2 className='client-custom-color-primary'>{title}</h2>
					<p dangerouslySetInnerHTML={{__html: text}} />
				</Col>

			</Row>

			<Row className='mobile-magic-box-card card-shadow d-flex d-md-none'>

				<Col className='card-illustration' xs={9}>
					<img className="client-custom-border-color-secondary" src={imageUrl} alt=''/>
				</Col>

				<Col className='card-text' xs={12}>
					<h2 className='client-custom-color-primary'>{title}</h2>
					<p dangerouslySetInnerHTML={{__html: text}} />
				</Col>

			</Row>
		</>
	)
}

export default MagicBoxCard;
import { createContext } from 'react';

// prepare auth context
export const AuthContext = createContext({
	auth: {
		logged: false,
		userCode: ''
	},
	setAuth: () => {},
	logout: () => {}
});


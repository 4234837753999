import React, {useEffect} from "react";
import useSequence from "../../hooks/Sequence";
import {Container, Row} from "react-bootstrap";
import './SequenceStoriesPage.scss';
import { LINK_SEQUENCES, LINK_STORY_DETAILS} from "../../navigation/routes";
import {useHistory, useParams} from "react-router-dom";
import Wall from "../../components/Story/Wall";
import {useStoreActions} from "easy-peasy";
import StoryCard from "../../components/Story/StoryCard";
import {useScrollPosition} from "../../hooks/ScrollPosition";


const SequenceStoriesPage = () => {

	const {
		sequence, sequenceStories, updateSequenceStories,
		tabletSequenceStories, initTabletSequenceStories,
		handlePrevSequenceStoriesPage, handleNextSequenceStoriesPage,
		shouldShowTabletSequenceStoriesPrevButton,
		shouldShowTabletSequenceStoriesNextButton
	} = useSequence();

	const {updateSequenceStoriesScrollPosition, focusCatalogScrollPosition} = useScrollPosition();

	const history = useHistory();
	const {uniqueId} = useParams();

	const setContext = useStoreActions((actions) => actions.pageContext.setContext);

	const stories = sequenceStories;

	const scrollAreaId = 'scrollable-sequence-stories';


	useEffect(() => {
		updateSequenceStories(uniqueId);
		setContext('sequenceStories');
	}, [])

	useEffect(() => {
		initTabletSequenceStories();
		focusCatalogScrollPosition(scrollAreaId);
	}, [sequenceStories]);

	useEffect(() => {

		return () => {
			updateSequenceStoriesScrollPosition(scrollAreaId);
		}
	}, [])


	const handleClick = (uniqueId) => {
		history.push(LINK_STORY_DETAILS(uniqueId));
	}

	const handleReturn = () => {
		history.push(LINK_SEQUENCES());
	}

	const storiesWallProps = {
		stories: tabletSequenceStories,
		handleClick: handleClick,
		handlePrevPage: handlePrevSequenceStoriesPage,
		handleNextPage: handleNextSequenceStoriesPage,
		shouldShowPrevButton: shouldShowTabletSequenceStoriesPrevButton,
		shouldShowNextButton: shouldShowTabletSequenceStoriesNextButton
	};


	return (
		<Container fluid className='sequence-stories-page'>

			<Container fluid className='desktop d-none d-md-flex'>
				<Row className='fake-header client-custom-color-secondary'>
					<h1 className='client-custom-color-secondary'><i className="fas fa-chalkboard-teacher client-custom-color-secondary"/> {sequence.name}</h1>
				</Row>
				<Wall {...storiesWallProps}/>
			</Container>

			<Container fluid className='mobile d-flex d-md-none'>
				<Row className='fake-header'>
					<i className="fas fa-arrow-left client-custom-color-secondary" onClick={handleReturn}/>
					<h3 className='client-custom-color-secondary'>{sequence.name}</h3>
				</Row>

				<div className='sequence-stories-listing' id={scrollAreaId}>

					{stories.map( (story) => {
						return <StoryCard story={story} onClick={() => handleClick(story.id)} key={story.id}/>
					})}
				</div>
			</Container>

		</Container>
	);
}

export default SequenceStoriesPage;
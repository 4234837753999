import React from 'react';
import {Button} from "react-bootstrap";

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";

import './StoryPage.scss';

const ExtraExternalLinkButton = (props) => {

    const {story} = props;

    const { auth } = useAuth();
	let userCode = auth().userCode;
	let deviceUniqueId = auth().deviceUniqueId;
	const api = new Api();
    
    const updateExternalLinkCount = () => {
		api.updateExternalLinkCount(userCode, story.id, story.links.clientCustomLink, deviceUniqueId).then( (response) => {
            
		}).catch( (reason) => {

		})
    }

    const content = story && story.links.clientCustomLink ?
        <Button className='story-external-link client-custom-link' onClick={updateExternalLinkCount}>
            <a href={story.links.clientCustomLink} target='_blank' rel='noopener noreferrer'> {story.links.clientButtonTitle} </a>
        </Button>
    : null;


    return <>{content}</>
}
export default ExtraExternalLinkButton
export function useInformationPage () {

	let storage = localStorage;

	function setHasSeenInformation () {
		storage.setItem('hasSeenInformation', 'true');
	}

	function getHasSeenInformation () {
		let hasSeenInformation = storage.getItem('hasSeenInformation');

		return hasSeenInformation ? hasSeenInformation : false;
	}

	return {setHasSeenInformation, getHasSeenInformation};
}
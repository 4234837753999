import React from 'react';
import {Button} from "react-bootstrap";

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";

import './StoryPage.scss';

const ExternalLinkButton = (props) => {

    const {story} = props;

    const { auth } = useAuth();
	let userCode = auth().userCode;
	let deviceUniqueId = auth().deviceUniqueId;
	const api = new Api();
    
    const updateExternalLinkCount = () => {
		api.updateExternalLinkCount(userCode, story.id, null, deviceUniqueId).then( (response) => {

		}).catch( (reason) => {

		})
    }
    
    return story && story.links.externalLink ?
    <Button className='story-external-link client-custom-button' onClick={updateExternalLinkCount}>
        <a href={story.links.externalLink} target='_blank' rel='noopener noreferrer'> En savoir plus </a>
    </Button>
: null;
}
export default ExternalLinkButton
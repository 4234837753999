import React, {useContext, useEffect} from "react";
import { useStoreState } from "easy-peasy";
import Loader from "../Widget/Loader";
import './DirectAuth.scss';
import {useHistory, useParams} from "react-router-dom";
import API from "../../api/api";
import {LINK_CATALOG, LINK_ERROR, LINK_INFORMATION, LINK_SEQUENCES, LINK_SEARCH} from "../../navigation/routes";
import {useTheme} from "../../hooks/Theme";
import {useMagicBox} from "../../hooks/MagicBox";
import {AuthContext} from "../../context/AuthContext";
import {useInformationPage} from "../../hooks/InformationPage";
import {useMenu} from "../../hooks/Menu";
import {useAuth} from "../../hooks/Auth";

const DirectAuth = () => {

	const { userCode } = useParams();
	const history = useHistory();
	const {setTheme, updateTheme} = useTheme();
	const {setMagicBoxLinks, updateEnableMagicBox} = useMagicBox();
	const informationPageManager = useInformationPage();
	const authManager = useContext(AuthContext);

	const {updateMenu} = useMenu();
	const api = new API();

	const deviceUniqueId = useAuth().auth().deviceUniqueId;

	const pushHistory = (webappMenu) => {

		updateTheme();
		const checkIfMenuExists = (itemIdentifier) => {
			return webappMenu?.items?.some(item => item.itemIdentifier === itemIdentifier);
		}
		if(checkIfMenuExists('HOME')) {
			history.push(LINK_CATALOG());
		} else if(checkIfMenuExists('SEQUENCE')) {
			history.push(LINK_SEQUENCES());
		} else if(checkIfMenuExists('SEARCH')) {
			history.push(LINK_SEARCH());
		} else {
			history.push(LINK_ERROR());
		}
		// if (informationPageManager.getHasSeenInformation()) {
		// }
		// else {
		// 	history.push(LINK_INFORMATION());
		// }
	}

	const authentication = () => {

		api.login(userCode, deviceUniqueId)
			.then((response) => {

				const {style, logo, isCustomized, magicBoxLinks} = response;
				setTheme(style, logo, isCustomized);
				setMagicBoxLinks(magicBoxLinks);
				updateEnableMagicBox(response.enableMagicBox);


				// bad user code
				if (response.status === 401) {
					history.push(LINK_ERROR());
				}
				else {
					authManager.setAuth(userCode);
					updateMenu(userCode)
						.then((response) => {
							pushHistory(response.webappMenu)});
				}

			})
			.catch((error) =>{
				history.push(LINK_ERROR());
		});
	}

	useEffect(() => {
		authentication();
	}, [])

	return (
		<div className='direct-auth'>
			<Loader/>
		</div>
	)
}

export default DirectAuth;
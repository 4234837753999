import {v4 as uuidv4} from 'uuid';

export function useAuth () {

	let storage = localStorage;

	const userCodeStorageKey = 'userCode';
	const deviceUniqueIdStorageKey = 'deviceUniqueId';

	function auth () {

		initDeviceUniqueId();
		const deviceUniqueId = storage.getItem(deviceUniqueIdStorageKey);

		let result = {userCode: '', logged: false, deviceUniqueId: deviceUniqueId}
		let userCode = storage.getItem(userCodeStorageKey);

		if (userCode) {
			result = {
				userCode: userCode,
				logged: true,
				deviceUniqueId: deviceUniqueId
			};
		}

		return result;
	}

	function setAuth (userCode) {
		storage.setItem(userCodeStorageKey, userCode);
	}

	function initDeviceUniqueId () {
		let deviceUniqueId = storage.getItem(deviceUniqueIdStorageKey);

		if (!deviceUniqueId) {
			deviceUniqueId = uuidv4();
			storage.setItem(deviceUniqueIdStorageKey, deviceUniqueId);
		}
	}

	function logout () {
		storage.removeItem(userCodeStorageKey);
	}

	function getDeviceUniqueId () {
		return storage.getItem(deviceUniqueIdStorageKey);
	}


	return {auth, setAuth, logout};
}
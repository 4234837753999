import React from "react";
import { Route, Redirect } from "react-router-dom";
import {LINK_HOMEPAGE} from "../../navigation/routes";
import {useAuth} from "../../hooks/Auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { auth } = useAuth();
	const isAuthenticated = auth().logged;

	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={LINK_HOMEPAGE()} />
				)
			}
		/>
	);
}

export default PrivateRoute;
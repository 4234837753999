import Helper from './Helper';

const links= {
    API_LOGIN : process.env.REACT_APP_API_V1_AUTH,
    API_STORIES : process.env.REACT_APP_API_V1_STORIES,
	API_STORY: process.env.REACT_APP_API_V1_STORY,
	API_SEARCH_STORIES: process.env.REACT_APP_API_V1_SEARCH_STORIES,
	API_UPDATE_EXTERNAL_LINK_COUNT: process.env.REACT_APP_API_V1_UPDATE_EXTERNAL_LINK_COUNT,
	API_UPDATE_LIKE_COUNT: process.env.REACT_APP_API_V1_UPDATE_LIKE_COUNT,
	API_SEQUENCES : process.env.REACT_APP_API_V1_SEQUENCES,
	API_SEQUENCE : process.env.REACT_APP_API_V1_SEQUENCE,
	API_MENU : process.env.REACT_APP_API_V1_MENU,
	API_GREEN_FACT_EVENT : process.env.REACT_APP_API_V1_GREEN_FACT_EVENT
};

export default class Api {

    constructor() {
        this.links = links;
        this.options = {
            method: 'GET',
            headers: new Headers(),
            mode: 'cors',
            cache: 'default'
        };
    }

    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            this.options.method = 'GET';
            delete this.options.body;

            let esc = encodeURIComponent;
            let query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&');

            fetch(url += '?' + query, this.options)
                .then((response) => {
                    response.json()
                        .then((data) => {
                            if (data.error) {
                                return reject(response)
                            }
                            return resolve(data)
                        });
                })
                .catch((error) => {
                    return reject(error)
                })
        });
    }

	post(url, params = {}) {
		return new Promise((resolve, reject) => {
			this.options.method = 'POST';
			let formData = null;
			if(Helper.isFormData(params)){
				formData = params;
			}else {
				formData = new FormData();
				Object.keys(params).map((key) => {
					formData.append(key, params[key]);
				});
			}
			this.options.body = formData;

			fetch(url, this.options)
				.then((response) => {
					if (!response.ok) {
						return reject(response)
					}
					response.json()
						.then((data) => {
							return resolve(data)
						})
						.catch((error)=>{
							return reject(error)
						});
				})
				.catch((error) => {
					return reject(error)
				})
		});
	}

    login(userCode, deviceUniqueId){
        const url = this.links.API_LOGIN;
        const data={userCode, deviceUniqueId};
        return new Promise((resolve, reject) => {
            return this.get(url,data).then(resolve, reject);
        });
    }

    getStories(userCode){
        const url = this.links.API_STORIES;
        const data={userCode};
        return new Promise((resolve, reject) => {
            return this.get(url,data).then(resolve, reject);
        });
    }

	getStory(userCode, storyId, deviceUniqueId){
        const url = this.links.API_STORY + '/' + storyId;
		const data={userCode, deviceUniqueId};
		return new Promise((resolve, reject) => {
			return this.get(url,data).then(resolve, reject);
		});
	}

	searchStories(userCode, search){
		const url = this.links.API_SEARCH_STORIES + '?userCode=' + userCode;
		const data={query: search};
		return new Promise((resolve, reject) => {
			return this.post(url,data).then(resolve, reject);
		});
	}

	updateExternalLinkCount(userCode, storyId, externalLink, deviceUniqueId){
		const url = this.links.API_UPDATE_EXTERNAL_LINK_COUNT.replace("{storyId}",storyId) + '?userCode=' + userCode + '&deviceUniqueId=' + deviceUniqueId;
		const params = externalLink ? {'clientLink':externalLink}:{};
		return new Promise((resolve, reject) => {
			return this.post(url,params).then(resolve, reject);
		});
	}

	updateLikeCount(userCode, storyId,like, deviceUniqueId){
		const url = this.links.API_UPDATE_LIKE_COUNT.replace("{storyId}",storyId) + '?userCode=' + userCode + '&deviceUniqueId=' + deviceUniqueId;
		const data={like: like};
		return new Promise((resolve, reject) => {
			return this.post(url,data).then(resolve, reject);
		});
	}

	getSequences(userCode){
		const url = this.links.API_SEQUENCES;
		const data = {userCode};
		return new Promise((resolve, reject) => {
			return this.get(url,data).then(resolve, reject);
		});
	}

	getSequence(userCode, uniqueId, deviceUniqueId){
		const url = this.links.API_SEQUENCE.replace("{uniqueId}",uniqueId);
		const data = {userCode, deviceUniqueId};
		return new Promise((resolve, reject) => {
			return this.get(url, data).then(resolve, reject);
		});
	}

	getMenu(userCode){
		const url = this.links.API_MENU;
		const data = {userCode};
		return new Promise((resolve, reject) => {
			return this.get(url, data).then(resolve, reject);
		});
	}

	sendGreenFactEvent(userCode, storyUniqueId, deviceUniqueId){
		const url = this.links.API_GREEN_FACT_EVENT;
		const data = {userCode, storyUniqueId, deviceUniqueId};
		return new Promise((resolve, reject) => {
			return this.post(url, data).then(resolve, reject);
		});
	}
}

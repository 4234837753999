import React, { useEffect } from 'react';
import {Container, Row} from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

import {useStory, useWallStories} from "../../hooks/Story";

import './Catalog.scss';
import {LINK_STORY_DETAILS} from "../../navigation/routes";
import {useHistory} from "react-router-dom";
import Wall from "../../components/Story/Wall";
import InfiniteScrollingBox from "../../components/Story/InfiniteScrollingBox";
import Loader from "../../components/Widget/Loader";

const CatalogPage = () => {

	const stories = useStoreState((state) => state.stories);
	const setContext = useStoreActions((actions) => actions.pageContext.setContext);

	const { fetchStories } = useStory();

	const history = useHistory();

	const {
		tabletStories, tabletStoriesIndex,
		shouldShowPrevButton, shouldShowNextButton,
		initTabletStories, handlePrev, handleNext
	} = useWallStories();

	const handleClick = (uniqueId) => {
		history.push(LINK_STORY_DETAILS(uniqueId));
	}

	const storiesWallProps = {
		stories: tabletStories,
		handleClick: handleClick,
		handlePrevPage: handlePrev,
		handleNextPage: handleNext,
		shouldShowPrevButton: shouldShowPrevButton,
		shouldShowNextButton: shouldShowNextButton
	};

	useEffect(() => {
		fetchStories();
		setContext('catalog');
	}, []);

	useEffect(() => {
		if (stories && stories.length) {
			initTabletStories();
		}
	}, [stories]);

	return(
		<Container fluid className='catalog-container' >

			{/* MOBILE SECTION */}
			<Row className='mobile d-flex d-md-none'>
				{stories && stories.length ?
					<InfiniteScrollingBox stories={stories} onClick={handleClick}/>
				: <Loader/>}
			</Row>

			{/* TABLET SECTION */}
			<Container fluid className='desktop d-none d-md-flex'>
				{stories && stories.length ?
					<Wall {...storiesWallProps}/>
				: <Loader/>}
			</Container>

		</Container>
	)

}

export default CatalogPage;
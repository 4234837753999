import React from 'react';
import './Footer.scss';
import Menu from "../../navigation/Menu";
import {useAuth} from "../../hooks/Auth";
import { useLocation } from 'react-router-dom'
import {ROUTE_HOMEPAGE} from "../../navigation/routes";

const Footer = () => {

	let { auth } = useAuth();

	// todo remove use of pathname, it's used to fix render bug
	const location = useLocation();
	let pathname = location.pathname;

	let loggedContent = <footer className="app-footer d-flex client-custom">
		<Menu menuType={'footer'}/>
	</footer>;

	let anonymousContent = <></>;

	return(
		<>
			{auth().logged && pathname !== ROUTE_HOMEPAGE ? loggedContent : anonymousContent}
		</>
	)

}

export default Footer;
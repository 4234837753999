import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {LINK_CATALOG, LINK_ERROR , LINK_HOMEPAGE, LINK_INFORMATION, LINK_SEARCH, LINK_SEQUENCES} from "../../navigation/routes";
import './Header.scss';
import logo from '../../images/logo.svg';
import {Container, Nav, Navbar} from "react-bootstrap";
import {useTheme} from "../../hooks/Theme";
import {AuthContext} from "../../context/AuthContext";
import {useMenu} from "../../hooks/Menu";
import {useMagicBox} from "../../hooks/MagicBox";

const Header = () => {

	const {getTheme} = useTheme();
	const logoUrl = getTheme().logo && getTheme().isCustomized ? getTheme().logo : logo ;

	const authManager = useContext(AuthContext);
	const auth = authManager.auth();

	const { removeEnableMagicBox } = useMagicBox();

	const logout = () => {
		authManager.logout();
		removeEnableMagicBox();
	}

	const {webappMenu, updateMenu, getMenu} = useMenu();
	const [menuContent, setMenuContent] = useState(null);
	const [targetLogo, setTargetLogo] = useState(LINK_CATALOG);

	const anonymousContent = null;

	useEffect(() => {
		updateMenu();
	}, [])

	useEffect(() => {
		if (webappMenu) {
			const computedMenu = getMenu();
			setMenuContent(computedMenu);
			const checkIfMenuExists = (itemIdentifier) => {
				return webappMenu?.items?.some(item => item.itemIdentifier === itemIdentifier);
			}
			if(checkIfMenuExists('HOME')) {
				setTargetLogo(LINK_CATALOG);
			} else if(checkIfMenuExists('SEQUENCE')) {
				setTargetLogo(LINK_SEQUENCES);
			} else if(checkIfMenuExists('SEARCH')) {
				setTargetLogo(LINK_SEARCH);
			} else {
				setTargetLogo(LINK_ERROR);
			}

		}
	}, [webappMenu])

	const loggedContent = <>
		<Navbar.Toggle aria-controls="basic-navbar-nav">
			<i className="fas fa-bars client-custom-color-secondary"/>
		</Navbar.Toggle>

		<Navbar.Collapse id="basic-navbar-nav">
			<Nav className="me-auto">
				{menuContent}
				<Nav.Link as={Link} to={LINK_HOMEPAGE} href={LINK_HOMEPAGE} onClick={logout} className='client-custom-color-secondary'><i className="fas fa-sign-out-alt"/> Déconnexion</Nav.Link>
			</Nav>
		</Navbar.Collapse>
	</>;

	return(
		<header className="app-header">
			<Navbar expand="lg" collapseOnSelect>
				<Container>
					<Navbar.Brand as={Link} to={targetLogo} className={!auth.logged ? 'm-auto' : null}>
						<img src={logoUrl} alt=""/>
					</Navbar.Brand>

					{auth.logged ? loggedContent : anonymousContent}

				</Container>
			</Navbar>
		</header>
	)

}

export default Header;
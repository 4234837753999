import {useStoreActions, useStoreState} from "easy-peasy";
import {CacheManager} from '../service/CacheManager';

const {themeManager} = CacheManager();

export const useTheme = () => {

	const theme = useStoreState((state) => state.theme);
	const setStyle = useStoreActions((actions) => actions.theme.setStyle);
	const setLogo = useStoreActions((actions) => actions.theme.setLogo);
	const setIsCustomized = useStoreActions((actions) => actions.theme.setIsCustomized);


	const getTheme = () => {
		return theme;
	}

	const setTheme = (style, logo, isCustomized) => {
		setStyle(style);
		setLogo(logo);
		setIsCustomized(isCustomized);

		themeManager().style().setPrimaryColor(style && style.primaryColor ? style.primaryColor : '');
		themeManager().style().setSecondaryColor(style && style.secondaryColor ? style.secondaryColor : '');
		themeManager().style().setTextColor(style && style.textColor ? style.textColor : '');

		themeManager().setLogo(logo ? logo : '');

		themeManager().setIsCustomized(isCustomized ? isCustomized : false);
	}

	const shouldUpdateTheme = () => {

		// TODO set conditions

		return theme.isCustomized;
	}

	const updateTheme = () => {

		if (shouldUpdateTheme()) {

			let style = document.getElementById('client-custom');

			// don't add style if it already exist
			if (!style) {

				let css = `
					.client-custom {
						background-color: ${theme.style.primaryColor} !important;
						color: ${theme.style.textColor} !important;
					}
					
					.client-custom a {
						color: ${theme.style.secondaryColor} !important;
					}

					.client-custom-filet {
						border-top: 1px solid ${theme.style.primaryColor} !important;
					}
					
					.client-custom-background-color {
						background-color: ${theme.style.primaryColor} !important;
					}
					
					.client-custom-color-primary {
						color: ${theme.style.primaryColor} !important;
					}
					
					.client-custom-border-color-primary {
						border-color: ${theme.style.primaryColor} !important;
					}	
					
					.client-custom-color-secondary {
						color: ${theme.style.secondaryColor} !important;
					}
					
					.client-custom-border-color-secondary {
						border-color: ${theme.style.secondaryColor} !important;
					}	
					
					.client-custom-button {
						color: ${theme.style.textColor} !important;
						background-color: ${theme.style.secondaryColor} !important;
						border-color: ${theme.style.secondaryColor} !important;
					}
					
					.client-custom-link {
						color: ${theme.style.secondaryColor} !important;
						background-color: ${theme.style.primaryColor} !important;
						border-color: ${theme.style.primaryColor} !important;
					}
					
					.client-custom-actions-icons {
						color: ${theme.style.secondaryColor} !important;
						border-color: ${theme.style.secondaryColor} !important;
					}
					
					.client-custom-border-color {
						border-color: ${theme.style.secondaryColor} !important;
					}
					
					.client-custom-background-gradient {
						background: radial-gradient(circle, ${theme.style.primaryColor} 0%, ${theme.style.primaryColor} 45%, white 90%) !important;
					}
					
					.client-custom-hand {
						color: ${theme.style.secondaryColor} !important;
					}
				`;

				let head = document.head || document.getElementsByTagName('head')[0];

				style = document.createElement('style');
				style.type = 'text/css';
				style.id = 'client-custom';

				head.appendChild(style);
				style.appendChild(document.createTextNode(css));
			}
		}

	}

	const removeTheme = () => {
		let style = document.getElementById('client-custom');
		if (style) {
			style.remove();
		}

		setLogo('');
		setIsCustomized(false);
	}

	return {getTheme, setTheme, updateTheme, removeTheme, setLogo, setStyle}
}
import React from "react";
import { Nav } from 'react-bootstrap';
import {Link} from "react-router-dom";
import './ErrorPage.scss';
import {ROUTE_HOMEPAGE} from "../../navigation/routes";

const ErrorPage = () => {

	return (
		<div className='error-page'>
			<p className='error-message'>Une erreur est survenue lors de votre navigation</p>
			<Nav.Link as={Link} to={ROUTE_HOMEPAGE} className='homepage-link'>Retour à l'accueil</Nav.Link>
		</div>
	);
}

export default ErrorPage;
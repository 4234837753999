import { useStoreState, useStoreActions } from 'easy-peasy';
import {useHistory} from "react-router-dom";


import {useAuth} from "./Auth";

import Api from "../api/api";
import {LINK_ERROR} from "../navigation/routes";
import {useScrollPosition} from "./ScrollPosition";

export const useStory = () => {

	const {resetScrollPosition} = useScrollPosition();
	const { auth } = useAuth();
	const { userCode } = auth();

	let api = new Api();
	let history = useHistory();

	const stories = useStoreState((state) => state.stories);
	const setStories = useStoreActions((actions) => actions.setStories);
	const lastUpload = useStoreState((state) => state.lastUpload);
	const lastUploadAction = useStoreActions((actions) => actions.lastUpload);

	// convert to milliseconds
	const storyUpdateFrequency = 1000 * 60 * 60;

	const fetchStories = () => {
		const now = new Date();
		if(!(stories && stories.length) || !lastUpload.time || (lastUpload.time.getTime() + storyUpdateFrequency < now.getTime() )){

				resetScrollPosition();
				api.getStories(userCode).then( (response) => {
					setStories(response.stories);
					lastUploadAction.setTime(now);
				}).catch( (response) => {
					history.push(LINK_ERROR());
				})
		}
	}

	const fetchOneStory = (uniqueId) => {
		api.getStory(userCode, uniqueId).then( (response) => {
			return response;
		}).catch( (reason) => {
			history.push(LINK_ERROR());
		})
	}

	return { fetchStories, fetchOneStory };
}

export const useWallStories = () => {

	const stories = useStoreState((state) => state.stories);
	const tabletStories = useStoreState((state) => state.tabletStories);
	const setTabletStories = useStoreActions((actions) => actions.setTabletStories);
	const tabletStoriesIndex = useStoreState((state) => state.tabletStoriesIndex);
	const setTabletStoriesIndex = useStoreActions((actions) => actions.setTabletStoriesIndex);

	const shouldShowPrevButton = tabletStoriesIndex !== 0;
	const shouldShowNextButton = stories && (((tabletStoriesIndex % 9) + tabletStoriesIndex) < stories.length - 9);

	const initTabletStories = () => {
		setTabletStories(stories.slice(tabletStoriesIndex,tabletStoriesIndex + 9));
	}

	const handlePrev = () => {
		let firstIndex = tabletStoriesIndex - 9;
		if (firstIndex < 0) {
			firstIndex = 0;
		}
		setTabletStories(stories.slice(firstIndex,tabletStoriesIndex));
		setTabletStoriesIndex(firstIndex);
	}

	const handleNext = () => {
		const firstIndex = tabletStoriesIndex + 9;
		const lastIndex = tabletStoriesIndex + 18;
		setTabletStories(stories.slice(firstIndex, lastIndex));
		setTabletStoriesIndex(tabletStoriesIndex + 9);
	}

	return {
		tabletStories, tabletStoriesIndex,
		shouldShowPrevButton, shouldShowNextButton,
		initTabletStories, handlePrev, handleNext
	};
} 
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Button, Spinner } from 'react-bootstrap';
import {NavLink } from "react-router-dom";
import {LINK_STORY_DETAILS} from "../../navigation/routes";
import './Video360Player.scss';
import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";

const Kaleidoscope = require('kaleidoscopejs');

const Video360Player = () => {

    let { auth } = useAuth();
    let { uniqueId } = useParams();
    let api = new Api();

    const [viewer,setViewer] = useState(null);
    const [play,setPlay] = useState(false);
    const [story, setStory] = useState(null);

    const PLAYER_ROOT = 'player-div';

	let userCode = auth().userCode;
	let deviceUniqueId = auth().deviceUniqueId;

    useEffect(() => {

		const heightButton=document.getElementById('controls').clientHeight;
		const heightVideoPlayer=document.getElementById('player-360-container').clientHeight-1.2*heightButton;
		const widthVideoPlayer=document.getElementById('player-360-container').clientWidth;

	    let theViewer = {};

		api.getStory(userCode, uniqueId, deviceUniqueId).then( (response) => {
			setStory(response);

			const videoOptions = {
				source: response.links.video,
				// verticalPanning: true,
				containerId: '#' + PLAYER_ROOT,
				height:heightVideoPlayer,
				width:widthVideoPlayer,
				autoPlay: play
			};

			theViewer = new Kaleidoscope.Video(videoOptions);

			setViewer(theViewer);
			theViewer.render();
			if(play) {theViewer.play();}
			else {theViewer.pause();}

		})

	    return () => {
			theViewer.destroy();
	    }

    },[]);

    const onPlay=() => {
        if(play) {viewer.pause();}
        else {viewer.play()}
        setPlay(!play);
    };

	return(
		<Container id="player-360-container" className="player-360-container" fluid>

			<Row>
				<div id={PLAYER_ROOT}/>
				{story ? null : <div className='mx-auto my-auto'> <Spinner animation="border" variant="light" /> </div>}
				<div className="mx-auto py-1" id="controls">
					<Button onClick={onPlay} className="mx-2" >
						{play ? <i className="fas fa-pause"/>:<i className="fas fa-play"/>}
					</Button>
					{story ?
						<NavLink to={LINK_STORY_DETAILS(story.id)} className='clickable-area'>
						<Button className="mx-2">
							<i className="fas fa-times"/>
						</Button>
					</NavLink>:
					null}
				</div>
			</Row>

		</Container>
	)

}

export default Video360Player;
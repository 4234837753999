
/****** ROUTER ENDPOINT ******/

export const ROUTE_HOMEPAGE = '/';
export const ROUTE_CATALOG = '/stories';
export const ROUTE_STORY = '/stories/:uniqueId';
export const ROUTE_STORY_DETAILS = '/stories/:uniqueId/details';
export const ROUTE_VIDEO360 = '/video360/:uniqueId';
export const ROUTE_SEARCH = '/search';
export const ROUTE_ERROR = '/error';
export const ROUTE_INFORMATION = '/information';
export const ROUTE_MAGIC_BOX = '/magic-box';
export const ROUTE_SEQUENCES = '/sequences';
export const ROUTE_SEQUENCE_STORIES = '/sequences/:uniqueId';
export const ROUTE_DIRECT_AUTH = '/direct-access/:userCode';


/****** NAVIGATION LINK  ******/

export const LINK_HOMEPAGE = () => '/';
export const LINK_CATALOG = () => '/stories';
export const LINK_STORY = (uniqueId) => '/stories/' + uniqueId;
export const LINK_STORY_DETAILS = (uniqueId) => '/stories/' + uniqueId + '/details';
export const LINK_VIDEO360 = (uniqueId) => '/video360/' + uniqueId;
export const LINK_SEARCH = () => '/search';
export const LINK_ERROR = () => '/error';
export const LINK_INFORMATION = () => '/information';
export const LINK_MAGIC_BOX = () => '/magic-box';
export const LINK_SEQUENCES = () => '/sequences';
export const LINK_SEQUENCE_STORIES = (uniqueId) => '/sequences/' + uniqueId;
export const LINK_DIRECT_AUH = (userCode) => '/direct-access/' + userCode;


/****** EXTERNAL LINK  ******/

export const EXTERNAL_LINK_HOMEPAGE = process.env.REACT_APP_EXTERNAL_LINK_INFORMATION;
export const EXTERNAL_LINK_STORY_REVIEW = process.env.REACT_APP_EXTERNAL_LINK_STORY_REVIEW;
export const EXTERNAL_LINK_APP_FEEDBACK = process.env.REACT_APP_EXTERNAL_LINK_APP_FEEDBACK;
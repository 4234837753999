export const CacheManager = () => {

	const storage = localStorage;

	const themeManager = () => {

		const primaryColorKey = 'primaryColor';
		const secondaryColorKey = 'secondaryColor';
		const textColorKey = 'textColor';

		const logoKey = 'logo';

		const isCustomizedKey = 'isCustomized';

		const style = () => {

			const primaryColor = storage.getItem(primaryColorKey);
			const secondaryColor = storage.getItem(secondaryColorKey);
			const textColor = storage.getItem(textColorKey);

			const setPrimaryColor = (primaryColor) => {
				storage.setItem(primaryColorKey, primaryColor);
			}

			const setSecondaryColor = (secondaryColor) => {
				storage.setItem(secondaryColorKey, secondaryColor);
			}

			const setTextColor = (textColor) => {
				storage.setItem(textColorKey, textColor);
			}


			return {primaryColor, secondaryColor, textColor, setPrimaryColor, setSecondaryColor, setTextColor};
		}

		const logo = storage.getItem(logoKey);
		const setLogo = (logo) => storage.setItem(logoKey, logo);

		const isCustomized = storage.getItem(isCustomizedKey);
		const setIsCustomized = (isCustomized) => storage.setItem(isCustomizedKey, isCustomized);

		return {style, logo, setLogo, isCustomized, setIsCustomized};
	}

	const scrollPositionManager = () => {

		const scrollPositionKey = 'scrollPosition';
		const sequenceStoriesScrollPositionKey = 'sequenceStoriesScrollPosition';

		const getScrollPosition = () => {
			const scrollPosition = parseInt(storage.getItem(scrollPositionKey));

			return scrollPosition ? scrollPosition : 0;
		}

		const setScrollPosition = (scrollPosition) => {
			storage.setItem(scrollPositionKey, scrollPosition);
		}

		const getSequenceStoriesScrollPosition = () => {
			const sequenceStoriesScrollPosition = parseInt(storage.getItem(sequenceStoriesScrollPositionKey));

			return sequenceStoriesScrollPosition ? sequenceStoriesScrollPosition : 0;
		}

		const setSequenceStoriesScrollPosition = (sequenceStoriesScrollPosition) => {
			storage.setItem(sequenceStoriesScrollPositionKey, sequenceStoriesScrollPosition);
		}

		return {getScrollPosition, setScrollPosition, getSequenceStoriesScrollPosition, setSequenceStoriesScrollPosition}
	}

	const magicBoxCacheManager = () => {

		const enableMagicBoxKey = 'enableMagicBox';

		const enableMagicBox = storage.getItem(enableMagicBoxKey);

		const setEnableMagicBox = (enableMagicBox) => {
			storage.setItem(enableMagicBoxKey, enableMagicBox);
		}

		const removeEnableMagicBox = () => {
			storage.removeItem(enableMagicBoxKey);
		}

		return {enableMagicBox, setEnableMagicBox, removeEnableMagicBox}
	}


	return {themeManager, scrollPositionManager, magicBoxCacheManager}
}
import React, {useEffect} from 'react';
import {Spinner} from "react-bootstrap";
import {NavLink} from "react-router-dom";

import {LINK_VIDEO360} from "../../navigation/routes";

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";

import './StoryPage.scss';
import NoAvailable from "../../components/Error/NoAvailable";

const StoryMedia = (props) => {

    const {story} = props;

    const hideMediaContextMenu = (mediaId) => {
        const media = document.getElementById(mediaId);

        if (media) {
			media.addEventListener('contextmenu', e => {
			e.preventDefault();
			});
            media.load();
		}
    }

    useEffect( () => {
            hideMediaContextMenu('video-control');
            hideMediaContextMenu('audio-control');
	}, [])


    useEffect( () => {
        hideMediaContextMenu('video-control');
        hideMediaContextMenu('audio-control');
	}, [story])
    
    return 	<>
        { story && story.typeOfMedia === "video" && story.is360 ?
            <div className='video-360-format'>
                <NavLink to={LINK_VIDEO360(story.id)}><i className="far fa-play-circle"/><br />
                <span>Voir la vidéo en 360</span></NavLink>
            </div>
            : null}

        { story && story.typeOfMedia === "video" && !story.is360 ?
            <div className='video-format'>
                <video id='video-control' controls controlsList='nodownload'>
                    <source src={story.links.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            : null}


        { story && story.typeOfMedia === "podcast" ?
            <div className='podcast-format'>
                <audio id='audio-control' controls controlsList='nodownload'>
                    <source src={story.links.podcast} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio> 
            </div>
            : null}

        { story && story.typeOfMedia ==="image" ?
            <div className='image-format'>
                <img src={story.links.image}  alt=''/>
            </div>
            : null}
        
        {!story ? 
            <div className='mx-auto my-auto'>
                <Spinner animation="border" variant="primary" />
            </div>
            :null}
        </>;

}

export default StoryMedia;
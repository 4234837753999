import React from 'react';
import { Switch, Route } from "react-router-dom";
import {
	ROUTE_HOMEPAGE,
	ROUTE_CATALOG,
	ROUTE_STORY_DETAILS,
	ROUTE_VIDEO360,
	ROUTE_SEARCH,
	ROUTE_ERROR,
	ROUTE_INFORMATION, ROUTE_MAGIC_BOX, ROUTE_SEQUENCES, ROUTE_SEQUENCE_STORIES, ROUTE_DIRECT_AUTH,
} from "../../navigation/routes";
import Login from "../../components/Login/Login";
import Video360Player from "../../components/Video/Video360Player";
import CatalogPage from "../../pages/Catalog/CatalogPage.jsx";
import './MainContent.scss';
import PrivateRoute from "../../components/Widget/PrivateRoute";
import StoryPage from "../../pages/Story/StoryPage.jsx";
import SearchPage from "../../pages/Search/SearchPage.jsx";
import ErrorPage from "../../components/Error/ErrorPage";
import PublicRoute from "../../components/Widget/PublicRoute";
import Information from "../../pages/Information/Information.jsx";
import MagicBoxPage from "../../pages/MagicBox/MagicBoxPage.jsx";
import {useTheme} from "../../hooks/Theme";
import SequenceListingPage from "../../pages/Sequence/SequenceListingPage";
import SequenceStoriesPage from "../../pages/Sequence/SequenceStoriesPage";
import DirectAuth from "../../components/Login/DirectAuth";

const MainContent = () => {

	const {updateTheme} = useTheme();
	updateTheme();

	return(
		<section className="app-content">

			<Switch>

				<Route exact path={ROUTE_DIRECT_AUTH} component={DirectAuth} />

				<Route exact path={ROUTE_HOMEPAGE} component={Login} />

				<PrivateRoute exact path={ROUTE_CATALOG} component={CatalogPage}/>

				<PrivateRoute exact path={ROUTE_VIDEO360} component={Video360Player}/>

				<PrivateRoute exact path={ROUTE_STORY_DETAILS} component={StoryPage}/>

				<PrivateRoute exact path={ROUTE_SEARCH} component={SearchPage}/>

				<PrivateRoute exact path={ROUTE_INFORMATION} component={Information}/>

				<PrivateRoute exact path={ROUTE_MAGIC_BOX} component={MagicBoxPage}/>

				<PrivateRoute exact path={ROUTE_SEQUENCES} component={SequenceListingPage}/>

				<PrivateRoute exact path={ROUTE_SEQUENCE_STORIES} component={SequenceStoriesPage}/>

				<Route exact path={ROUTE_ERROR} component={ErrorPage}/>

				<Route path={'*'} component={ErrorPage}/>

			</Switch>


		</section>
	)

}

export default MainContent;
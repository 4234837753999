import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import {NavLink, useParams, useHistory} from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSwipeable } from 'react-swipeable';

import {LINK_STORY_DETAILS, LINK_ERROR, LINK_MAGIC_BOX, LINK_CATALOG, LINK_SEARCH, LINK_SEQUENCE_STORIES} from "../../navigation/routes";

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";

import StoryMedia from './StoryMedia.jsx';
import ExternalLinkButton from './ExternalLinkButton.jsx';
import ExtraExternalLinkButton from './ExtraExternalLinkButton.jsx';
import LikeDislikeButton from './LikeDislikeButton.jsx';
import './StoryPage.scss';
import useSequence from "../../hooks/Sequence";
import GreenFact from "../../components/Story/GreenFact";
import {useMagicBox} from "../../hooks/MagicBox";

const StoryPage = () => {

	let { uniqueId } = useParams();

	const [story, setStory] = useState(null);
	const [uniqueIdStory, setUniqueIdStory] = useState(uniqueId);
    const [isSequenceError, setIsSequenceError] = useState(false);

	let api = new Api();
	let history = useHistory();
	let { auth } = useAuth();
	const {getMagicBox} = useMagicBox();

	let userCode = auth().userCode;
	let deviceUniqueId = auth().deviceUniqueId;
	const shouldShowMagicBox = getMagicBox().enableMagicBox;

	const context = useStoreState((state) => state.pageContext.context);

    const {
		sequence,
		} = useSequence();

    const BACK = () => {
		switch (context) {
			case 'catalog':
				history.push(LINK_CATALOG());
				break;
			case 'search':
				history.push(LINK_SEARCH());
				break;
			case 'sequenceStories':
				history.push(LINK_SEQUENCE_STORIES(sequence.id));
				break;
			default:
				history.goBack();
		}
    }

	const fetchStory = (uniqueID) => {
		return 	api.getStory(userCode, uniqueID, deviceUniqueId)
			.then( (response) => {
				if (response.status >= 300) {
					return Promise.reject(response);
				}
				setIsSequenceError(false)
				setStory(response);
			})
			.catch( (reason) => {
				if (context === 'sequenceStories') {
					setIsSequenceError(true);
					return 
				}
				history.push(LINK_ERROR());
			})
	}

    const PREV = () => {
		if(context == 'sequenceStories') {
			const currentIdStory = sequence.stories.findIndex((s) => s.id == uniqueIdStory );
			const idStory = currentIdStory > 0 ? currentIdStory -1:sequence.stories.length-1 ;
			setUniqueIdStory(sequence.stories[idStory].id);
		}
    }

    const NEXT = () => {
		if(context == 'sequenceStories') {
			const currentIdStory = sequence.stories.findIndex((s) => s.id == uniqueIdStory );
			const idStory = currentIdStory == sequence.stories.length -1 ? 0:currentIdStory+1 ;
			setUniqueIdStory(sequence.stories[idStory].id);
		}
    }

	const dispatchOpenGreenFactEvent = () => {
		api.sendGreenFactEvent(userCode, story.id, deviceUniqueId);
	}

	useEffect( () => {
		fetchStory(uniqueId);
	}, [])

	useEffect( () => {
		if (story) {
			history.push(LINK_STORY_DETAILS(uniqueIdStory));
			fetchStory(uniqueIdStory);
		}
	}, [uniqueIdStory])


	useEffect( () => {
		const videoPlayer = document.getElementById('video-control');

		if(videoPlayer) {
			videoPlayer.addEventListener('contextmenu', e => {
			e.preventDefault();
			});
		}

	}, [story])

	const backgroundStyle = (story && story.typeOfMedia === "video" && story.is360) || (story && story.typeOfMedia === "podcast") ? {
		backgroundImage: "url(" + story.links.thumbnail.default + ")",
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	} : null;

	const actions = <>
		{story ? <div className='like'><LikeDislikeButton isLike={true} story={story} /></div>:null}
		{story ? <div className='dislike'><LikeDislikeButton isLike={false} story={story} /></div>:null}
		{shouldShowMagicBox ?
			<div className='share'><NavLink to={LINK_MAGIC_BOX}><i
				className="fas fa-box-open client-custom-actions-icons"/></NavLink></div>
			: null
		}
	</>;

	const actionsNav = context == 'sequenceStories' ?
		<Row className='actions-section'>
			<div onClick={PREV} className='navButton'><i className="fas fa-arrow-left"/></div>
			<div onClick={BACK} className='navButton'><i className="fas fa-arrow-up"/></div>
			<div onClick={NEXT} className='navButton'><i className="fas fa-arrow-right"/></div>
		</Row>:null;

	const returnSection =
		<div className='return-section' >
			<i className="fas fa-times-circle" onClick={BACK}/>
		</div>
	;

	return (
		<Container className="expanded-story" fluid>

			{/* DESKTOP SECTION & TABLET SECTION*/}
			<div  className='d-none d-md-block desktop-content'>

				<Row className="story-content">
					{/* Adding style here because need different property on desktop*/}
					<Col className="expanded-story-illustration" style={backgroundStyle ? {...backgroundStyle, border: 'solid 15px white'} : null}>
						{!isSequenceError && <StoryMedia story={story} />}
					</Col>

					<Col className="expanded-story-text">
						{actionsNav}

						{context !== 'sequenceStories' ? returnSection : null}

					    {	
						  isSequenceError ? 
						  <div>
						  <h1 className='story-title' style={{color: 'red'}}>{ "Oups..."}</h1>
							<div>Ce contenu est en train d'être modifié ou ne semble plus disponible....</div>
						  </div>
						  
						   :
							<>
								<h1 className='story-title client-custom-color-primary'>{ story ? story.name : null}</h1>

								<div dangerouslySetInnerHTML={{ __html: story ? story.description : null }}/>

								{story && story.greenFact && <GreenFact greenFact={story.greenFact} viewMoreAction={dispatchOpenGreenFactEvent}/>}

								<Row className="story-row-external-link">
									{story && story.links && story.links.externalLink ? <Col className="align-self-center"><ExternalLinkButton story={story} /></Col> : null}
									{story && story.links &&  story.links.clientCustomLink ? <Col className="align-self-center"><ExtraExternalLinkButton story={story} /></Col>:null}
								</Row>

								<Row className='actions-section'>
									{actions}
								</Row>
							</>
						
						}
					</Col>
				</Row>

			</div>

			{/* MOBILE SECTION */}
			<div className='d-block d-md-none mobile-content'> {/* Div only to show content on mobile device */}

				{returnSection}

				
				{	
					isSequenceError ? 
					<Row className="expanded-story-text">
						<h1 className='story-title' style={{color: 'red'}}>{ "Oups..."}</h1>
						<div>Ce contenu est en train d'être modifié ou ne semble plus disponible....</div>
					</Row>
					
					:
					<>
						<Row className="expanded-story-illustration" style={backgroundStyle}>
							{!isSequenceError && <StoryMedia story={story} />}
						</Row>

						<Row className="expanded-story-text">
							<h1 className='story-title client-custom-color-primary'>{ story ? story.name : null}</h1>
							<div dangerouslySetInnerHTML={{ __html: story ? story.description : null }}/>
						</Row>

						{story && story.greenFact && <GreenFact greenFact={story.greenFact} viewMoreAction={dispatchOpenGreenFactEvent}/>}

						<Row className="story-row-external-link">
							{story && story.links && story.links.externalLink ? <Col className="align-self-center"><ExternalLinkButton story={story} /></Col> : null}
							{story && story.links && story.links.clientCustomLink ? <Col className="align-self-center"><ExtraExternalLinkButton story={story} /></Col>:null}
						</Row>

						<Row className='actions-section'>
							{actions}
						</Row>
					</> }
			</div>

        </Container>
	);
}

export default StoryPage;
import React from "react";
import {useAuth} from "./Auth";
import Api from "../api/api";
import {useStoreActions, useStoreState} from "easy-peasy";
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import {LINK_CATALOG, LINK_INFORMATION, LINK_SEARCH, LINK_SEQUENCES} from "../navigation/routes";

export const useMenu = () => {

	const api = new Api();
	const { auth } = useAuth();
	const { userCode } = auth();

	const webappMenu = useStoreState((state) => state.Menu.webappMenu);
	const setWebappMenu = useStoreActions((actions) => actions.Menu.setWebappMenu);


	const updateMenu = (tmpUserCode = userCode) => {

		return api.getMenu(tmpUserCode).then( (response) => {
			setWebappMenu(response.webappMenu);
			return response;
		}).catch( (reason) => {

		})

	}

	const getMenu = () => {

		const menuItems = webappMenu.items.map( (item) => {

			const itemProperties = resolveWebappMenuItemIdentifier(item.itemIdentifier);

			return <Nav.Link
				as={Link}
				to={itemProperties.linkTo}
				href={itemProperties.linkTo}
				className='client-custom-color-secondary'>
				<i className={itemProperties.iconClassName}/>
				{' ' + item.name}
			</Nav.Link>;
		})

		return(
			<>
				{menuItems}
			</>
		)
	}

	return {webappMenu, updateMenu, getMenu};
}
const itemsIdentifierProperties = [
	{
		identifier: 'HOME',
		linkTo: LINK_CATALOG,
		iconClassName: 'fas fa-home'
	},
	{
		identifier: 'SEARCH',
		linkTo: LINK_SEARCH,
		iconClassName: 'fas fa-search'
	},
	{
		identifier: 'SEQUENCE',
		linkTo: LINK_SEQUENCES,
		iconClassName: 'fas fa-compass'
	},
	{
		identifier: 'INFORMATION',
		linkTo: LINK_INFORMATION,
		iconClassName: 'fas fa-info-circle'
	}
];

// Resolve properties that match with item identifier, like target url and icon class name
function resolveWebappMenuItemIdentifier (itemIdentifier) {

	return itemsIdentifierProperties.find((itemProperties) => itemProperties.identifier === itemIdentifier);
}
import React from "react";
import loader from '../../images/loader/loader.svg';
import './Loader.scss';

const Loader = () => {

	return(
		<div className='m360-loader'>
			<img src={loader} alt=''/>
		</div>
	)
}

export default Loader;
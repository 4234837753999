import {openDB} from 'idb';

export const IndexedDBManager = () => {

	const magicBoxCacheManager = () => {

		let dbVersion = 1;
		let dbName = 'm360';
		let magicBoxStoreName = 'magicBoxLinks';
		let magicBoxStoreKeyPath = 'externalUrl'

		const initDb = async () => {
			return await openDB(dbName, dbVersion, {
				upgrade(database, oldVersion, newVersion, transaction) {
					const store = database.createObjectStore(magicBoxStoreName, {
						keyPath: magicBoxStoreKeyPath
					});

					store.createIndex(magicBoxStoreKeyPath, magicBoxStoreKeyPath);
				}
			});
		}

		const setMagicBoxLinks = async (links) => {
			const db = await initDb();
			const tx = db.transaction(magicBoxStoreName, 'readwrite');

			links.forEach( (link) => {
				tx.store.put(link);
			});

			await tx.done;

			return await db.getAllFromIndex(magicBoxStoreName, magicBoxStoreKeyPath);
		}

		const getMagicBoxLinks = async () => {
			const db = await initDb();
			return await db.getAllFromIndex(magicBoxStoreName, magicBoxStoreKeyPath);
		}

		const deleteMagicBoxLinks = async () => {
			const db = await initDb();
			await db.clear(magicBoxStoreName);
		}

		return {setMagicBoxLinks, getMagicBoxLinks, deleteMagicBoxLinks};

	}

	return {magicBoxCacheManager}
}
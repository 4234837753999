import {useRef} from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import {CacheManager} from '../service/CacheManager';

const {scrollPositionManager} = CacheManager();

export const useScrollPosition = () => {

	const scrollPosition = useStoreState((state) => state.Scroll.scrollPosition);
	const setScrollPosition = useStoreActions((actions) => actions.Scroll.setScrollPosition);

	const sequenceStoriesScrollPosition = useStoreState((state) => state.Scroll.sequenceStoriesScrollPosition);
	const setSequenceStoriesScrollPosition = useStoreActions((actions) => actions.Scroll.setSequenceStoriesScrollPosition);

	const scrollItemRef = useRef();

	const updateScrollPosition = (scrollPosition) => {
		setScrollPosition(scrollPosition);
		scrollPositionManager().setScrollPosition(scrollPosition);
	}

	const resetScrollPosition = () => {
		scrollPositionManager().setScrollPosition(0);
		setScrollPosition(0);
	}

	const updateSequenceStoriesScrollPosition = (divId) => {
		let domElement = document.getElementById(divId);

		setSequenceStoriesScrollPosition(domElement.scrollTop);
		scrollPositionManager().setSequenceStoriesScrollPosition(domElement.scrollTop);
	}

	const resetSequenceStoriesScrollPosition = () => {
		scrollPositionManager().setSequenceStoriesScrollPosition(0);
		setSequenceStoriesScrollPosition(0);
	}

	const focusCatalogScrollPosition = (divId) => {
		let domElement = document.getElementById(divId);

		domElement.scrollTo({
			top: sequenceStoriesScrollPosition,
			left: 0,
			behavior: 'auto'
		});
	}


	return {
		scrollItemRef, updateScrollPosition, scrollPosition, resetScrollPosition, sequenceStoriesScrollPosition,
		focusCatalogScrollPosition, updateSequenceStoriesScrollPosition, resetSequenceStoriesScrollPosition
	}
}
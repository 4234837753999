import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from "react-router-dom";
import {Container, Form, Row, Col} from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";
import {LINK_STORY_DETAILS, LINK_ERROR} from "../../navigation/routes";

import './SearchPage.scss';
import ButtonLoaderComponent from "../../components/Widget/ButtonLoaderComponent";
import StoryCard from "../../components/Story/StoryCard";

const SearchPage = () => {

	const { auth } = useAuth();
	const { userCode } = auth();
	const carouselInstance = useRef(null);

	const [isSending, setIsSending] = useState(false);
	const [noResult, setNoResult] = useState(false);

	const setContext = useStoreActions((actions) => actions.pageContext.setContext);

	const search = useStoreState((state) => state.search);
	const setSearch = useStoreActions((actions) => actions.search);

	let api = new Api();
	let history = useHistory();

	const carouselOptions = [
		{ width: 1, itemsToShow: 3, pagination: false, showArrows: true },
	];

	if( window.innerWidth <= 800 ) {
		carouselOptions[0].showArrows = false;
	}

	// update selected video when carousel change
	const onCarouselChange = (currentItemObject, currentPageIndex) => {
		setSearch.updateCarouselPosition(currentItemObject.index);
	}

	useEffect(() => {
		if(carouselInstance.current) {
			carouselInstance.current.goTo(search.carouselPosition);
		}
		setContext('search');
	}, [])

	const makeSearch = () => {
		setSearch.setStories([]);
		setSearch.updateCarouselPosition(0);
		setNoResult(false);
		setIsSending(true);
		api.searchStories(userCode, search.keyword).then( (response) => {
			setSearch.setStories(response.stories);
			setIsSending(false);
		}).catch( (response) => {
			setIsSending(false);
			response.json().then((content) => {
				if(content.message && content.message === "No stories available") {
					setNoResult(true);
				}
                else {history.push(LINK_ERROR());}
            })
		})
	}

	const redirectToVideoDetails = (video) => {
		history.push(LINK_STORY_DETAILS(video.id))
	}

	return (
		<Container fluid className='search-container'>

			<div className='d-flex mobile-content'>
				<div className='top-section'>

					<Row className='fake-header client-custom-color-primary'>
						<h1>Recherche</h1>
						<i className="fas fa-search"/>
					</Row>

					<div className='search-section'>
						<Col md={{ span: 6, offset: 3 }}>
							<Form onSubmit={(e) => {
									e.preventDefault();
									makeSearch();
								}}>
								<Form.Control
									type="text"
									placeholder="Explorer les stories"
									onChange={(e) => {setSearch.setKeyword(e.target.value);}}
									value={search.keyword}
								/>

								<ButtonLoaderComponent
									type="submit"
									msg="Rechercher"
									isSending={isSending}
								/>
							</Form>
						</Col>
					</div>
					{noResult ?
						<p className='result-title no-story'>Aucune story ne correspond à votre critère de recherche</p>
						:null}
				</div>

				<Row className='result-content'>
					{search.stories.length ?
						search.stories.map( (story) => {
							return <Col xs={12} sm={6} md={4} lg={3}  className='story-card-wrapper' key={story.id}>
								<StoryCard story={story} onClick={() => redirectToVideoDetails(story)} />
							</Col>
						})
						: null
					}
				</Row>

			</div>

        </Container>
	);
}

export default SearchPage;
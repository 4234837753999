import React, {useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import './MagicBoxPage.scss';
import logoSatisfaction from './../../images/magic_box/satisfaction.jpg';
import logoWork from '../../images/magic_box/logo-travail.svg';
import logoPaint from  '../../images/magic_box/peinture.svg';
import logoNumericLink from  '../../images/magic_box/lien-numerique.jpg';
import MagicBoxCard from "../../components/MagicBox/MagicBoxCard.jsx";
import {useMagicBox} from "../../hooks/MagicBox";
import {useTheme} from "../../hooks/Theme";

const MagicBoxPage = () => {

	const staticLinks = [
		// {
		// 	imgUrl: logoWork,
		// 	title: 'Connaissez-vous l\'apprentissage ?',
		// 	text: 'Être apprenti(e) c\'est suivre une formation et travailler dans une entreprise, en alternance. ' +
		// 		'Vous êtes salarié(e) et donc rémunéré(e). Votre CFA vous donne la formation théorique que la pratique en entreprise permet de consolider avec ' +
		// 		'des contrats à partir de l\'âge de 15 ans et jusque 30 ans. Une autre façon de se former à découvrir...',
		// 	externalUrl: 'https://www.alternance.emploi.gouv.fr/portail_alternance/jcms/leader_9155/avec-l-apprentissage-demarretastory'
		// },
		{
			imgUrl: logoPaint,
			title: 'La filière de la peinture propose un test de personnalité !',
			text: 'Une vingtaine de questions pour savoir si vous êtes plutôt créatif, plutôt social ou encore entrepreneur... ' +
				'Basée sur la méthode RIASEC, ce test de personnalité est une façon originale ' +
				'de découvrir les métiers de la finition, plus variés que vous ne le pensez !',
			externalUrl:'https://infopro-finition.com/test-de-personnalite/'
		},
		{
			imgUrl: logoNumericLink,
			title: '#Lien numérique, utiles ensemble',
			text: 'La fédération des entreprises du numérique propose huit découvertes des métiers du numérique en racontant ' +
				'l\'histoire de ce que le numérique rend possible. À partir d\'un cas réel, découvrez les technologies et les équipes qui se cachent derrière !',
			externalUrl:'https://www.concepteursdavenirs.fr/lien-numerique.html'
		}
	];

	const [data, setData] = useState(staticLinks);

	const {getTheme} = useTheme();
	const {getMagicBoxLinks} = useMagicBox();

	useEffect(() => {
		if (getTheme().isCustomized) {
			getMagicBoxLinks().then( (magicBoxLinks) => {
				setData([...magicBoxLinks, ...data, ]);
			});

		}
	}, [])


	return(
		<Container className='magic-box-container'>

			<Row className='fake-header client-custom-color-primary'>
				<p>Magic Box</p>
				<i className="fas fa-box-open"/>
			</Row>

			<p className='magic-box-description'>
				Ici, vous recevez des propositions d'actions personnalisées pour construire votre projet professionnel.
			</p>

			<div className='magic-box-listing'>

				{/*todo get a true unique key*/}
				{data.map( (currentData, key) => {
					return <a target='_blank' rel='noopener noreferrer' href={currentData.externalUrl} className='external-link' key={key}>
						<MagicBoxCard imageUrl={currentData.imgUrl} text={currentData.text} title={currentData.title} />
					</a>;
				})}

			</div>

		</Container>
	)
}

export default MagicBoxPage;
import React, {useContext, useState} from 'react';
import { useHistory } from "react-router-dom";
import {
	LINK_CATALOG,
	LINK_ERROR,
	LINK_INFORMATION,
	LINK_SEQUENCES,
	LINK_SEARCH
} from "../../navigation/routes";
import { Container, Form, Row, Col } from 'react-bootstrap';
import './Login.scss';
import ButtonLoaderComponent from "../../components/Widget/ButtonLoaderComponent";
import API from "../../api/api";
import backgroundImage from '../../images/login/webapp-background.jpg';
import {AuthContext} from "../../context/AuthContext";
import {useInformationPage} from "../../hooks/InformationPage";
import {useTheme} from "../../hooks/Theme";
import {useMagicBox} from "../../hooks/MagicBox";
import {useMenu} from "../../hooks/Menu";
import {useAuth} from "../../hooks/Auth";

const Login = () => {

	let history = useHistory();
	const [isSending, setIsSending] = useState(false);
	const [userCode, setUserCode] = useState('');
	const [userCodeError, setUserCodeError] = useState(false);
	let authManager = useContext(AuthContext);
	let informationPageManager = useInformationPage();

	const deviceUniqueId = useAuth().auth().deviceUniqueId;

	let backgroundStyle = {
		backgroundImage: "url(" + backgroundImage + ")",
		backgroundPosition: 'center 10%',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	};

	const {setTheme, updateTheme} = useTheme();
	const {setMagicBoxLinks, updateEnableMagicBox} = useMagicBox();

	const {webappMenu, updateMenu} = useMenu();

	const pushHistory = (webappMenu) => {

		updateTheme();
		const checkIfMenuExists = (itemIdentifier) => {
			return webappMenu?.items?.some(item => item.itemIdentifier === itemIdentifier);
		}
		if(checkIfMenuExists('HOME')) {
			history.push(LINK_CATALOG());
		} else if(checkIfMenuExists('SEQUENCE')) {
			history.push(LINK_SEQUENCES());
		} else if(checkIfMenuExists('SEARCH')) {
			history.push(LINK_SEARCH());
		} else {
			history.push(LINK_ERROR());
		}
		// if (informationPageManager.getHasSeenInformation()) {
			// history.push(LINK_CATALOG());
		// }
		// else {
			// history.push(LINK_INFORMATION());
		// }
	}

	if(authManager.auth().logged){
		if(!webappMenu) {updateMenu(authManager.auth().userCode)
			.then((response) => {
				pushHistory(response.webappMenu)});
		} else {
			pushHistory(webappMenu);
		}
	}

	const authentication = (e) => {
		e.preventDefault();
		setIsSending(true);

		const api = new API();

		api.login(userCode, deviceUniqueId).then((response) => {
			setIsSending(false);

			const {style, logo, isCustomized, magicBoxLinks} = response;
			setTheme(style, logo, isCustomized);
			setMagicBoxLinks(magicBoxLinks);
			updateEnableMagicBox(response.enableMagicBox);


			// bad user code
			if (response.status === 401) {
				setUserCode('');
				setUserCodeError(true);
			}
			else {
				authManager.setAuth(userCode);
				updateMenu(userCode)
					.then((response) => {
						pushHistory(response.webappMenu)});
				
			}

		}).catch((error) =>{
			// redirect
			setIsSending(false);
			history.push(LINK_ERROR());
		});
	}

	return(
		<Container className='login' style={backgroundStyle} fluid>

				<Row className='login-input'>

					<Col sm={12} md={6} className='input-field'>
						<Form.Control
							type="text"
							placeholder={!userCodeError ? "Code de connexion" : "Votre code est incorrect" }
							onChange={(e) => setUserCode(e.target.value)}
							value={userCode}
							className={userCodeError ? 'error-feedback' : null}
						/>
					</Col>

					<Col sm={12} md={3} className='validate-button mt-3 mt-md-0 text-right'>
						<ButtonLoaderComponent 
							onClick={authentication}
							msg="OK"
							isSending={isSending}
						/>
					</Col>

				</Row>

				<div className='credits-section'>
					 <p><span className="text-light" >version 1.1</span></p>
				</div>

		</Container>
	)

}

export default Login;
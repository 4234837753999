import React from 'react';
import { Spinner, Button } from 'react-bootstrap';

/**
 * ButtonLoader widget
 *
 * @version 1.0.0
 * @param {boolean} disabled  default false
 * @param {function} onClick  callback function
 * @param {boolean} isSending  default false
 * @param {string} msg button message
 * @param {string} variant  bootstrap className variant - default 'variant'
 * @example
 *      <ButtonLoaderComponent 
 *          variant='secondary'
 *          disabled={false}
 *          onClick={() => CallBack()} 
 *          msg="Envoyer" 
 *          isSending={false} />
 */

 
const ButtonLoaderComponent = (props) => {

    const { variant, disabled, msg, onClick, isSending, ...rest } = props;
    
    const message = msg ? msg : 'Envoyer';

    const spinner =
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true">
            <span className="sr-only">Envoi en cours</span>
        </Spinner>;

    return (

        <Button
            variant={variant ? variant : 'primary'}
            disabled={disabled ? disabled : false}
            onClick={onClick? onClick : null}
            className='client-custom-button'
            {...rest}
        >
            { isSending ? spinner : message }
        </Button>

    );

}

export default ButtonLoaderComponent;
import {useStoreActions, useStoreState} from "easy-peasy";
import {IndexedDBManager} from "../service/IndexedDBManager";
import {CacheManager} from "../service/CacheManager";

export const useMagicBox = () => {

	const magicBox = useStoreState((state) => state.magicBox);
	const setCustomLinks = useStoreActions((actions) => actions.magicBox.setCustomLinks);
	const setEnableMagicBox = useStoreActions((actions) => actions.magicBox.setEnableMagicBox);

	const {magicBoxCacheManager : magicBoxIndexedDbCacheManager} = IndexedDBManager();
	const {magicBoxCacheManager : magicBoxLocalStorageCacheManager} = CacheManager();

	const getMagicBox = () => {
		return magicBox;
	}

	const setMagicBoxLinks = async (links) => {

		await magicBoxIndexedDbCacheManager().deleteMagicBoxLinks();

		if (links && links.length) {
			setCustomLinks(links);
			await magicBoxIndexedDbCacheManager().setMagicBoxLinks(links);
		}
	}

	const getMagicBoxLinks = async () => {
		return await magicBoxIndexedDbCacheManager().getMagicBoxLinks();
	}

	const updateEnableMagicBox = (enableMagicBox) => {
		if (enableMagicBox !== undefined) {
			setEnableMagicBox(enableMagicBox);
			magicBoxLocalStorageCacheManager().setEnableMagicBox(enableMagicBox);
		}
	}

	const removeEnableMagicBox = () => {
		setEnableMagicBox(true);
		magicBoxLocalStorageCacheManager().removeEnableMagicBox();
	}

	return {getMagicBox, setMagicBoxLinks, getMagicBoxLinks, updateEnableMagicBox, removeEnableMagicBox};
}
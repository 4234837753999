import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import './Information.scss';
import {useInformationPage} from "../../hooks/InformationPage";
import {LINK_CATALOG} from "../../navigation/routes";
import {useHistory} from "react-router-dom";
import {useTheme} from "../../hooks/Theme";

const Information = () => {

	let informationPageManager = useInformationPage();
	let history = useHistory();
	const {updateTheme, removeTheme} = useTheme();

	const saveHasSeenInformation = () => {
		informationPageManager.setHasSeenInformation();
		history.push(LINK_CATALOG());
	}

	return(
		<Container fluid className='information-container'>

			<Row className='d-none d-md-flex desktop-content'>

				<Col className='video-section'>
					<video id='video-control' controls controlsList='nodownload'>
						<source src={'https://cdn2.metiers360.com/docs/comment-ca-marche-webapp.mp4'} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</Col>

				<Col className='text-section'>
					<h1 className='title client-custom-color-primary'>Comment ça marche ?</h1>

					<p>
						La webapp Métiers 360 vous permet d’explorer des univers professionnels en vidéo ou en image.
						Swipez d’une page à l’autre pour trouver des idées, découvrir la face cachée de plus de 300 métiers !
					</p>

					<p>
						La <b>magic box</b> vous propose des pistes d’action qui seront bientôt personnalisées en fonction de votre parcours d’exploration !
					</p>

					<Button onClick={saveHasSeenInformation} className='client-custom-button'> OK, j'ai compris </Button>
				</Col>

			</Row>

			<div className='d-block d-md-none mobile-content'>

				<div className='video-section'>
					<video id='video-control' controls controlsList='nodownload'>
						<source src={'https://cdn2.metiers360.com/docs/comment-ca-marche-webapp.mp4'} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>

				<Row className='text-section'>
					<h1 className='title client-custom-color-primary'>Comment ça marche ?</h1>

					<p>
						La webapp Métiers 360 vous permet d’explorer des univers professionnels en vidéo ou en image.
						Swipez d’une page à l’autre pour trouver des idées, découvrir la face cachée de plus de 300 métiers !
					</p>

					<p>
						La <b>magic box</b> vous propose des pistes d’action qui seront bientôt personnalisées en fonction de votre parcours d’exploration !
					</p>

					<Button onClick={saveHasSeenInformation} className='client-custom-button'> OK, j'ai compris </Button>
				</Row>

				{/* For testing customization, TODO remove it*/}
				{/* <Row className='text-section'>
					<Button onClick={updateTheme}> Test personnalisation </Button>
					<Button onClick={removeTheme}> Retrait personnalisation </Button>
				</Row> */}


			</div>

		</Container>
	);
}

export default Information;
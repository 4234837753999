import React, {useState} from "react";
import './GreenFact.scss';


const GreenFact = ({greenFact, viewMoreAction}) => {

	const defaultContentClassName = 'html-content';
	const defaultButtonClassName = 'show-more';
	const classNameTriggerAnimation = 'green-fact-opening';

	const [customContentClassName, setCustomContentClassName] = useState(defaultContentClassName);
	const [customButtonClassName, setCustomButtonClassName] = useState(defaultButtonClassName);

	const openGreenFact = () => {

		if (viewMoreAction) {
			viewMoreAction();
		}

		setCustomContentClassName(`${defaultContentClassName} ${classNameTriggerAnimation}`);
		setCustomButtonClassName(`${defaultButtonClassName} d-none`); // d-none come from bootstrap default class style

	}

	return(
		<div className='green-fact-item'>
			<p className='title'> <i className="fas fa-leaf"/> Green Facts</p>

			<div className={customContentClassName} dangerouslySetInnerHTML={{ __html: greenFact}}/>

			<p className={customButtonClassName} onClick={openGreenFact}>
				Lire plus
			</p>

		</div>
	)
}

export default GreenFact;
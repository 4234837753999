import React from 'react';

import './StoryCard.scss';

const StoryCard = (props) => {

	const {story, onClick} = props;

	let storyFormat = null;

	const storyIllustrationClassName = story.featured ? 'featured client-custom-border-color-secondary':null;

	switch (story.typeOfMedia) {

		case 'video':
			if (story.is360)
				storyFormat = <i className="fas fa-vr-cardboard"/>;
			else
				storyFormat = <i className="fas fa-video"/>;
			break;
		case 'image':
			storyFormat = <i className="fas fa-image"/>;
			break;
		case 'podcast':
			storyFormat = <i className="fas fa-headphones"/>;
			break;
	}

	return (
		<div id='story-card' className="story-card card-shadow" onClick={onClick}>

			<div className='story-title client-custom-color-primary'>
				<p>{story.name}</p>
			</div>

            <div className='story-illustration'>
	            <img src={story.links.thumbnail['default']} className={storyIllustrationClassName} alt={''}/>
            </div>

			<div className='story-description client-custom-color-primary'>
				<div className='story-text' dangerouslySetInnerHTML={{__html: story.description.split("</p>")[0].concat('</p>')}} />
				<div className='story-description-separator client-custom-border-color-primary'/>
				<div className='story-format'>{storyFormat}</div>
			</div>
        </div>
	);
}

export default StoryCard;
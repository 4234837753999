import React, {useState, useEffect} from 'react';
import {Button, Container, Row, Col, Spinner} from "react-bootstrap";
import {NavLink, useParams, useHistory} from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSwipeable, Swipeable } from 'react-swipeable';

import {EXTERNAL_LINK_STORY_REVIEW, LINK_VIDEO360, LINK_STORY_DETAILS, LINK_ERROR, LINK_MAGIC_BOX, LINK_CATALOG} from "../../navigation/routes";

import Api from "../../api/api";
import {useAuth} from "../../hooks/Auth";
import {useStory} from "../../hooks/Story";

import StoryMedia from './StoryMedia.jsx';
import ExternalLinkButton from './ExternalLinkButton.jsx';
import './StoryPage.scss';
import NoAvailable from "../../components/Error/NoAvailable";


const LikeDislikeButton = (props) => {
    const {isLike, story} = props;
	let { uniqueId } = useParams();
	let { auth } = useAuth();
	let userCode = auth().userCode;
	let deviceUniqueId = auth().deviceUniqueId;

    const initialCount = isLike && story ? story.isLiked:story.isUnliked;
    const [count, setCount] = useState(initialCount ? initialCount:0);
    const [isLoading, setIsloading] = useState(false);


	let api = new Api();

    const handleClik = () => {
        setIsloading(true);
        setCount(count+1);
		const like = isLike ? 1:-1;
        api.updateLikeCount(userCode, uniqueId, like, deviceUniqueId).then((result =>{
			setIsloading(false);
        }))
	}

    const iconClass = isLike ? "fas fa-thumbs-up":"fas fa-thumbs-down";
    return isLoading ? <Spinner animation="grow" />:<a onClick={handleClik} className='client-custom-color-secondary'><i className={`${iconClass} client-custom-actions-icons`}/>&nbsp;{count}</a>;
}

export default LikeDislikeButton
import React, {useEffect, useRef} from "react";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import './InfiniteScrollingBox.scss';
import StoryCard from "./StoryCard";
import {useScrollPosition} from "../../hooks/ScrollPosition";

const InfiniteScrollingBox = ({stories, onClick}) => {

	const {scrollItemRef, updateScrollPosition, scrollPosition} = useScrollPosition();
	const length = stories.length;

	useEffect(() => {

		return () => {
			if (scrollItemRef.current) {
				updateScrollPosition(scrollItemRef.current.state.scrollOffset);
			}
		}
	}, [])

	useEffect(() => {
		if (scrollItemRef.current) {
			/* CARE: this part is for focusing last element viewed storage in localstorage, on page refresh or first load
			   to be logic we should just scroll to scroll position value from hook
			   but due to library logic, it will not work (value comparison)
			   the trick is to make new value different from last value known by library
			 */
			const tmpValue = scrollPosition + 1;
			scrollItemRef.current.scrollTo(tmpValue);
		}

	}, [stories])

	const itemList = ({index, style}) => {

		const currentStory = stories[index];
		const itemStyle = {
			...style,
			padding: '15px'
		};

		return(
			<div style={itemStyle}>
				<StoryCard story={currentStory} onClick={() => onClick(currentStory.id)}/>
			</div>
		)
	}

	return (
		<div className='infinite-scrolling-box'>
			<AutoSizer>
				{({ height, width }) => (
					<List
						height={height}
						itemSize={height * 0.9}
						itemCount={length}
						width={width}
						ref={scrollItemRef}
						initialScrollOffset={scrollPosition}
					>
						{itemList}
					</List>
				)}
			</AutoSizer>
		</div>
	)
}

export default InfiniteScrollingBox;
import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import './Wall.scss';
import StoryCard from "./StoryCard";

const Wall = ({ stories, handleClick, handlePrevPage, handleNextPage, shouldShowPrevButton, shouldShowNextButton }) => {

	return(
		<Container fluid className='stories-wall'>

			<Row className='wall-content'>
				{
					stories.map( (story, key) => {
						return <Col className='tablet-story-card' md={4} key={key}>
							<StoryCard story={story} onClick={() => handleClick(story.id)}/>
						</Col>
					})
				}
			</Row>

			<Row className='navigation-section'>
				{ shouldShowPrevButton ? <Button onClick={handlePrevPage} className='client-custom-button'> Précédent</Button> : null}
				{ shouldShowNextButton ? <Button onClick={handleNextPage} className='client-custom-button'> Suivant</Button> : null}
			</Row>

		</Container>
	);
}

export default Wall;
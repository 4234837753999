import {createStore, action} from 'easy-peasy';
import {CacheManager} from '../service/CacheManager';

const {themeManager, scrollPositionManager, magicBoxCacheManager} = CacheManager();

export const store = createStore({
    stories: [],
    setStories: action((state, stories) => {
        state.stories = stories;
    }),


    lastUpload: {
        time: null,
        setTime: action((state, time) => {
            state.time = time;
        })
    },

	tabletStories: [],
	setTabletStories: action((state, tabletStories) => {
		state.tabletStories = tabletStories;
	}),
	tabletStoriesIndex: 0,
	setTabletStoriesIndex: action((state, tabletStoriesIndex) => {
		state.tabletStoriesIndex = tabletStoriesIndex;
	}),

    pageContext: {
        context:'catalog',
        setContext: action((state,context) => {
            state.context = context;
        })
    },

    search: {
        stories : [],
        setStories: action((state, stories) => {
            state.stories = stories;
        }),
        keyword : '',
        setKeyword: action((state, keyword) => {
            state.keyword = keyword;
        }),
        carouselPosition: 0,
        updateCarouselPosition: action((state, position) => {
            state.carouselPosition = position;
        }),
    },

	theme: {

    	style: {
    		primaryColor: themeManager().style().primaryColor,
		    secondaryColor: themeManager().style().secondaryColor,
		    textColor: themeManager().style().textColor,
		    fontFamily: ''
	    },

		setStyle: action((state, style) => {
			state.style = style;
		}),

		logo: themeManager().logo,

		setLogo: action((state, logo) => {
			state.logo = logo;
		}),

		isCustomized: themeManager().isCustomized,

		setIsCustomized: action((state, isCustomized) => {
			state.isCustomized = isCustomized;
		})
	},

	magicBox: {

    	customLinks: [],

		setCustomLinks: action((state, links) => {
			state.customLinks = links;
		}),

		// enableMagicBox: magicBoxCacheManager().enableMagicBox !== null ? !!(magicBoxCacheManager().enableMagicBox) : true,
		enableMagicBox: (magicBoxCacheManager().enableMagicBox !== null) ?
			(magicBoxCacheManager().enableMagicBox === 'true')
			: true
		,
		setEnableMagicBox: action((state, enableMagicBox) => {
			state.enableMagicBox = enableMagicBox;
		}),
	},

	Sequence: {

		sequences: [],
		setSequences: action((state, sequences) => {
			state.sequences = sequences;
		}),

		tabletSequences: [],
		setTabletSequences: action((state, tabletSequences) => {
			state.tabletSequences = tabletSequences;
		}),

		tabletSequencesIndex: 0,
		setTabletSequencesIndex: action((state, tabletSequencesIndex) => {
			state.tabletSequencesIndex = tabletSequencesIndex;
		}),


		sequence: {},
		setSequence: action((state, sequence) => {
			state.sequence = sequence;
		}),

		sequenceStories: [],
		setSequenceStories: action((state, sequenceStories) => {
			state.sequenceStories = sequenceStories;
		}),

		tabletSequenceStories: [],
		setTabletSequenceStories: action((state, tabletSequenceStories) => {
			state.tabletSequenceStories = tabletSequenceStories;
		}),

		tabletSequenceStoriesIndex: 0,
		setTabletSequenceStoriesIndex: action((state, tabletSequenceStoriesIndex) => {
			state.tabletSequenceStoriesIndex = tabletSequenceStoriesIndex;
		}),

	},

	Scroll: {
		scrollPosition: scrollPositionManager().getScrollPosition(),
		setScrollPosition: action((state, scrollPosition) => {
			state.scrollPosition = scrollPosition;
		}),

		sequenceStoriesScrollPosition: scrollPositionManager().getSequenceStoriesScrollPosition(),
		setSequenceStoriesScrollPosition: action((state, sequenceStoriesScrollPosition) => {
			state.sequenceStoriesScrollPosition = sequenceStoriesScrollPosition;
		}),
	},

	Menu: {
		webappMenu: null,
		setWebappMenu: action((state, webappMenu) => {
			state.webappMenu = webappMenu;
		})
	}

});
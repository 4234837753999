import React, { useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from 'easy-peasy';

import { store } from './providers/store';
import {AuthContext} from "./context/AuthContext";
import {useAuth} from "./hooks/Auth";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/App.scss';
import './fonts/fonts.scss';

import Header from "./layouts/Header/Header";
import Footer from "./layouts/Footer/Footer";
import MainContent from "./layouts/MainContent/MainContent";


function App() {

	let initAuth = useAuth(); // get init auth object manager for local storage
	const [auth, setAuth] = useState(initAuth.auth()); // init auth context with local storage value

	// function to get auth
	const syncAuth = () => {
		return initAuth.auth();
	}

	// function to sync update auth
	const syncSetAuth = (userCode) => {
		initAuth.setAuth(userCode);
		let currentAuth = initAuth.auth();
		setAuth(currentAuth);
	}

	// function to sync logout
	const syncLogout = () => {
		initAuth.logout();
		let currentAuth = initAuth.auth();
		setAuth(currentAuth);
	}

	return (
		<div className="App">
			<StoreProvider store={store}>
			<AuthContext.Provider value={{auth: syncAuth, setAuth: syncSetAuth, logout: syncLogout}}>
				<Router>
					<Header/>

					<MainContent/>

					{/*<Footer/>*/}
				</Router>
			</AuthContext.Provider>
			</StoreProvider>
		</div>
	);
}

export default App;

import { useStoreState, useStoreActions } from 'easy-peasy';
import Api from "../api/api";
import {useAuth} from "./Auth";
import {LINK_ERROR} from "../navigation/routes";
import {useHistory} from "react-router-dom";

const useSequence = () => {

	const pagingThreshold = 9;
	const api = new Api();
	const { auth } = useAuth();
	const { userCode, deviceUniqueId } = auth();
	const history = useHistory();

	/*
	 * Variables partie sequence
	 */

	const sequences = useStoreState((state) => state.Sequence.sequences);
	const setSequences = useStoreActions((actions) => actions.Sequence.setSequences);

	const tabletSequences = useStoreState((state) => state.Sequence.tabletSequences);
	const setTabletSequences = useStoreActions((actions) => actions.Sequence.setTabletSequences);
	const tabletSequencesIndex = useStoreState((state) => state.Sequence.tabletSequencesIndex);
	const setTabletSequencesIndex = useStoreActions((actions) => actions.Sequence.setTabletSequencesIndex);

	/*
	 * Variables partie sequence stories
	 */

	const sequence = useStoreState((state) => state.Sequence.sequence);
	const setSequence = useStoreActions((actions) => actions.Sequence.setSequence);

	const sequenceStories = useStoreState((state) => state.Sequence.sequenceStories);
	const setSequenceStories = useStoreActions((actions) => actions.Sequence.setSequenceStories);

	const tabletSequenceStories = useStoreState((state) => state.Sequence.tabletSequenceStories);
	const setTabletSequenceStories = useStoreActions((actions) => actions.Sequence.setTabletSequenceStories);
	const tabletSequenceStoriesIndex = useStoreState((state) => state.Sequence.tabletSequenceStoriesIndex);
	const setTabletSequenceStoriesIndex = useStoreActions((actions) => actions.Sequence.setTabletSequenceStoriesIndex);


	const shouldShowTabletSequenceStoriesPrevButton = tabletSequenceStoriesIndex !== 0;
	const shouldShowTabletSequenceStoriesNextButton = ((tabletSequenceStoriesIndex % 9) + tabletSequenceStoriesIndex) < sequenceStories.length - 9;

	/*
	 * Partie sequence
	 */

	const updateSequences = () => {
		return api.getSequences(userCode).then( (response) => {
			setSequences(response.sequences
				.sort((seqA,seqB) => seqA.position - seqB.position ));
		}).catch( () => {
			//history.push(LINK_ERROR());
		});
	}

	const initTabletSequences = () => {
		if(sequences && sequences.length > 0) {
			setTabletSequences(sequences.slice(tabletSequencesIndex,tabletSequencesIndex + pagingThreshold));
		}
	}

	const handlePrevSequencePage = () => {
		let firstIndex = tabletSequencesIndex - pagingThreshold;
		if (firstIndex < 0) {
			firstIndex = 0;
		}
		setTabletSequences(sequences.slice(firstIndex,tabletSequencesIndex));
		setTabletSequencesIndex(firstIndex);
	}

	const handleNextSequencePage = () => {
		const firstIndex = tabletSequencesIndex + pagingThreshold;
		const lastIndex = tabletSequencesIndex + 2 * pagingThreshold;
		setTabletSequences(sequences.slice(firstIndex, lastIndex));
		setTabletSequencesIndex(tabletSequencesIndex + pagingThreshold);
	}

	/*
	 * Partie sequence stories
	 */

	const updateSequenceStories = (uniqueId) => {

		api.getSequence(userCode, uniqueId, deviceUniqueId).then( (response) => {
			setSequenceStories(response.stories);
			setSequence(response);
		}).catch( () => {
			history.push(LINK_ERROR());
		});

	}

	const initTabletSequenceStories = () => {
		setTabletSequenceStories(sequenceStories.slice(tabletSequenceStoriesIndex,tabletSequenceStoriesIndex + pagingThreshold));
	}

	const handlePrevSequenceStoriesPage = () => {
		let firstIndex = tabletSequenceStoriesIndex - pagingThreshold;
		if (firstIndex < 0) {
			firstIndex = 0;
		}
		setTabletSequenceStories(sequenceStories.slice(firstIndex,tabletSequenceStoriesIndex));
		setTabletSequenceStoriesIndex(firstIndex);
	}

	const handleNextSequenceStoriesPage = () => {
		const firstIndex = tabletSequenceStoriesIndex + pagingThreshold;
		const lastIndex = tabletSequenceStoriesIndex + 2 * pagingThreshold;
		setTabletSequenceStories(sequenceStories.slice(firstIndex, lastIndex));
		setTabletSequenceStoriesIndex(tabletSequenceStoriesIndex + pagingThreshold);
	}

	return {
		sequences, setSequences, tabletSequences, tabletSequencesIndex,
		initTabletSequences, handlePrevSequencePage, handleNextSequencePage, updateSequences,
		sequence, sequenceStories,setSequenceStories, tabletSequenceStories, tabletSequenceStoriesIndex,
		shouldShowTabletSequenceStoriesPrevButton, shouldShowTabletSequenceStoriesNextButton,
		initTabletSequenceStories, handlePrevSequenceStoriesPage, handleNextSequenceStoriesPage, updateSequenceStories
	};
}

export default useSequence;
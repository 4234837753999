import React, {useState, useEffect} from "react";
import './SequenceListingPage.scss';
import { Col, Container, Row, Spinner} from "react-bootstrap";
import useSequence from "../../hooks/Sequence";
import {useHistory} from "react-router-dom";
import {LINK_SEQUENCE_STORIES} from "../../navigation/routes";
import {useScrollPosition} from "../../hooks/ScrollPosition";


const SequenceListingPage = () => {

	const history = useHistory();
	const {sequences, updateSequences, initTabletSequences} = useSequence();
    const [isLoading, setIsloading] = useState(sequences && sequences.length > 0 ? false:true);

    const {resetSequenceStoriesScrollPosition} = useScrollPosition();

	const handleClick = (uniqueId) => {
		history.push(LINK_SEQUENCE_STORIES(uniqueId));
	}

	useEffect(() => {
		resetSequenceStoriesScrollPosition();
		updateSequences()
			.then(() => {
				setIsloading(false);
			});
	}, []);

	useEffect(() => {
		initTabletSequences();
	}, [sequences]);

	const noSequencesMsg = <div className = 'noSequences'><p className = 'client-custom'>Pas encore de parcours</p></div>;

	return (
		<Container fluid className='sequence-listing-page'>

			<Container className='desktop d-none d-md-flex'>

				<Row className='fake-header client-custom-color-primary'>
					<h1>Nos parcours</h1>
					<i className="fas fa-chalkboard-teacher"/>
				</Row>

				<Row className='sequences-section'>
					{
						isLoading ? 
							<div className = 'noSequences'><Spinner animation="grow" /></div>:
							sequences && sequences.length > 0 ?
								sequences
									.map( (sequence) => {
									return <Row className='sequence-card card-shadow' key={sequence.id} onClick={() => handleClick(sequence.id)}>

										<Col className='sequence-illustration' xs={3}>
											<img src={sequence.links.thumbnail.default} className='client-custom-border-color' alt=''/>
										</Col>

										<Col className='sequence-information' xs={9}>
											<h2 className='client-custom-color-primary'>{sequence.name}</h2>
											<p dangerouslySetInnerHTML={{__html: sequence.description}} />
										</Col>

									</Row>
								}):
								noSequencesMsg
					}
				</Row>

			</Container>

			<Container fluid className='mobile d-flex d-md-none'>

				<Row className='fake-header client-custom-color-primary'>
					<h1>Nos parcours</h1>
					<i className="fas fa-chalkboard-teacher"/>
				</Row>

				<Row className='sequences-section'>
					{
						isLoading ? 
							<div className = 'noSequences'><Spinner animation="grow" /></div>:
							sequences && sequences.length > 0 ?
								sequences.map( (sequence) => {
									return <Row className='sequence-card card-shadow' key={sequence.id} onClick={() => handleClick(sequence.id)}>

										<Col className='sequence-illustration' xs={9}>
											<img src={sequence.links.thumbnail.default} className='client-custom-border-color' alt=''/>
										</Col>

										<Col className='sequence-information' xs={12}>
											<h2 className='client-custom-color-primary'>{sequence.name}</h2>
											<p dangerouslySetInnerHTML={{__html: sequence.description}} />
										</Col>

									</Row>
								}):
								noSequencesMsg
					}
				</Row>

			</Container>

		</Container>
	);
}

export default SequenceListingPage;
import React, {useContext} from "react";
import { Nav } from 'react-bootstrap';
import {Link} from "react-router-dom";
import {LINK_CATALOG, LINK_HOMEPAGE, LINK_INFORMATION, LINK_SEARCH, LINK_SEQUENCES} from "./routes";
import './Menu.scss';
import {AuthContext} from "../context/AuthContext";
import logo from "../images/logo.svg";
import {useTheme} from "../hooks/Theme";

const Menu = ({menuType}) => {

	let authManager = useContext(AuthContext);

	const {getTheme} = useTheme();
	const isCustomized = getTheme().isCustomized;

	const logout = () => {
		authManager.logout();
	}

	return (
		<div className='app-menu'>
			{isCustomized ?
				<div className='logo d-none d-md-flex'>
					<p>Un service</p>
					<img src={logo} alt=''/>
				</div>
				: null
			}

			<div className='links'>
				<Nav.Link as={Link} to={LINK_CATALOG}>{ menuType === 'footer' ? <i className="fas fa-home"/> : 'Accueil'}</Nav.Link>
				<Nav.Link as={Link} to={LINK_SEARCH}>{ menuType === 'footer' ? <i className="fas fa-search"/> : 'Explorer'}</Nav.Link>
				<Nav.Link as={Link} to={LINK_SEQUENCES}>{ menuType === 'footer' ? <i className="fas fa-compass"/> : 'Parcours'}</Nav.Link>
				<Nav.Link as={Link} to={LINK_INFORMATION}>{ menuType === 'footer' ? <i className="fas fa-info-circle"/> : 'Information'}</Nav.Link>
				<Nav.Link as={Link} to={LINK_HOMEPAGE} onClick={logout}>{ menuType === 'footer' ? <i className="fas fa-sign-out-alt"/> : 'Déconnexion'}</Nav.Link>
			</div>
		</div>
	)
}

export default Menu;